import React from 'react';
import Analyst from 'src/components/Analyst/AnalystPage';
import PropTypes from 'prop-types';

const AnalystTemplate = ({ post, data, allImages }) => {
    const acfData = post.acfData;

    const productsData = data.analystRelatedProducts.edges.map((productNode) => {
        const product = productNode.node;
        const name = product.name;
        const mainCategoryName = product.main_category_name;
        const edition = product.edition;
        const image = {
            src: {
                small: product.fields.productImage[0].localFile.childImageSharp.fluid
            },
        };
        const parsedAcf = JSON.parse(product.acf_data);
        const prices = product.prices;

        const acfData = {
            permalink: parsedAcf.permalink,
            productInformation: {
                shortDescription: parsedAcf.product_information.short_description,
            },
        };

        const finalObj = {
            product: {
                mainCategoryName,
                name,
                image,
                prices,
                edition,
                showPrices: false,
                acfData,
            }
        };

        return finalObj;
    });

    const postsData = data.relatedPosts.edges;

    return (
        <Analyst
            heading={post.postTitle}
            position={acfData.position}
            image={acfData.photo}
            linkedInSrc={acfData.linkedin}
            text={acfData.presentation}
            productsData={productsData}
            postsData={postsData}
            allImages={allImages}
        />
    );
};

AnalystTemplate.propTypes = {
    allImages: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default AnalystTemplate;
